<template>
	<div class="exception-body flex align-items-center justify-content-center">
		<div class="exception-panel p-5 flex flex-column justify-content-center">
			<h4 class="font-bold">ERROR</h4>
			<span class="line-height-3">Unexpected error happened. Resource is not available.</span>

			<img src="layout/images/pages/error.svg" alt="error" class="my-5" height="150">

			<Button type="button" label="Go to Dashboard" @click="goDashboard"></Button>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			goDashboard(){
				this.$router.push({ path: '/' });
			}
		}
	}
</script>

<style scoped>

</style>
